import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Container from "./container";
import FacebookIcon from "./icons/facebook-icon";
import InstagramIcon from "./icons/instagram-icon";

const Footer = () => {
  return (
    <footer className="p-10 bg-primary text-secondary ">
      <Container className="grid grid-cols-1 lg:grid-cols-3">
        <div className="flex flex-col items-center text-xl text-white ">
          <a
            href="https://tidens-shop.de"
            target="_blank"
            rel="noreferrer"
            className="py-3"
          >
            Onlineshop
          </a>
          <a
            href="https://tidens-shop.de/collections/geschenkgutscheine"
            target="_blank"
            rel="noreferrer"
            className="py-3"
          >
            Gutscheine
          </a>

          <Link to="/standort" className="py-3">
            Standort
          </Link>
          <Link to="/faq" className="py-3">
            FAQ
          </Link>
          <Link to="/vdws-versicherung" className="py-3">
            VDWS SafetyTool
          </Link>
        </div>
        <div className="flex flex-col items-center text-xl text-white ">
          <Link to="/kurstermine" className="py-3">
            Kurstermine
          </Link>
          <Link to="/kontakt" className="py-3">
            Kontakt
          </Link>
          <Link to="/impressum" className="py-3">
            Impressum
          </Link>
          <Link to="/datenschutz" className="py-3">
            Datenschutz
          </Link>
          <Link to="/agb" className="py-3">
            AGB
          </Link>
        </div>
        <div className="flex flex-col items-center text-xl text-white font-bold">
          <p className="text-base pt-3 font-normal">Find us on Social Media</p>
          <div className="pt-4 flex flex-row">
            <div className="px-3">
              <a
                href="https://www.instagram.com/tidenssurf/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
            </div>
            <div className="px-3">
              <a
                href="https://www.facebook.com/tidenssurf"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <div className="container mx-auto pb-[40px] lg:pb-[80px] px-4 lg:px-4 ">
        <div className="flex justify-center">
          <a href="https://www.vdws.de/" target="_blank" rel="noreferrer">
            <StaticImage
              src="../images/VDWS-Logo-Tidens.png"
              alt="VDWS Logo"
              width={468}
              quality={90}
              className=""
              objectFit="cover"
            />
          </a>
        </div>
      </div>
      <div className="text-center text-white text-sm pt-2">
        Alle Preise inkl. 19% MwSt.
      </div>
    </footer>
  );
};

export default Footer;
