import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Logo from "../components/logo-d";
import { Link } from "gatsby";
import Burger from "./icons/burger";
import Clear from "./icons/clear";
import FacebookIcon from "./icons/facebook-icon";
import InstagramIcon from "./icons/instagram-icon";
import Arrow from "./icons/arrow";

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [subNavOpen, setSubNavOpen] = useState(false);

  const handleNavToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const handleSubNavToggle = () => {
    setSubNavOpen(!subNavOpen);
  };

  return (
    <header className="bg-secondary flex justify-center py-4 lg:py-6 border-b-2 border-info drop-shadow-md sticky top-0 z-50">
      <Helmet>
        <body className={`${navbarOpen ? "overflow-hidden" : ""}`} />
      </Helmet>
      <a
        href="https://www.instagram.com/tidenssurf"
        target="_blank"
        rel="noreferrer"
        className="absolute top-6 lg:top-[41px] left-6 lg:left-10 hover:cursor-pointer"
      >
        <InstagramIcon color="#102337" />
      </a>
      <a
        href="https://www.facebook.com/tidenssurf"
        target="_blank"
        rel="noreferrer"
        className="absolute top-[25px] lg:top-[42px] left-[62px] lg:left-[74px] hover:cursor-pointer"
      >
        <FacebookIcon color="#102337" />
      </a>
      <Link to="/">
        <Logo />
      </Link>
      <button
        className="absolute top-6 lg:top-[41px] right-6 lg:right-10 hover:cursor-pointer"
        onClick={handleNavToggle}
        tabIndex={0}
      >
        <Burger />
      </button>
      <div
        className={` flex justify-center content-center w-full h-screen fixed top-0 left-0 z-50 bg-primary ${
          navbarOpen ? "d-flex" : "hidden"
        }`}
      >
        <nav className="flex text-secondary">
          <ul
            className={`${
              subNavOpen ? "hidden" : "flex"
            } flex-col items-center justify-center`}
          >
            <li className="text-2xl py-2">
              <Link to="/">Startseite</Link>
            </li>
            <li className="text-2xl py-2">
              <button className="cursor-pointer inline-flex" onClick={handleSubNavToggle}>
                Kurse <Arrow color="menu" className="mt-1 ml-2"/>
              </button>
            </li>
            <li className="text-2xl py-2">
              <Link to="/kurstermine">Kurstermine</Link>
            </li>
            <li className="text-2xl py-2">
              <Link to="/verleih">Verleih</Link>
            </li>
            <li className="text-2xl py-2">
              <a href="https://tidens-shop.de" target="_blank" rel="noreferrer">Onlineshop</a>
            </li>
            <li className="text-2xl py-2">
              <Link to="/standort">Standort</Link>
            </li>
            <li className="text-2xl py-2">
              <Link to="/team">Team</Link>
            </li>
            <li className="text-2xl py-2">
              <Link to="/kontakt">Kontakt</Link>
            </li>
          </ul>
          <ul
            className={`${
              subNavOpen ? "flex" : "hidden"
            } flex flex-col items-center justify-center`}
          >
            <li className="text-2xl py-2 mb-4">
              <button className="cursor-pointer inline-flex" onClick={handleSubNavToggle}>
              <Arrow color="menu" className="mt-[3px] mr-2 rotate-180"/>Zurück 
              </button>
            </li>
            <li className="text-2xl py-2">
              <Link to="/kurse/wingfoilen">Wingsurfen / Wingfoilen</Link>
            </li>
            <li className="text-2xl py-2">
              <Link to="/kurse/wellenreiten">Wellenreiten</Link>
            </li>
            <li className="text-2xl py-2">
              <Link to="/kurse/windsurfen">Windsurfen</Link>
            </li>
            <li className="text-2xl py-2">
              <Link to="/kurse/kitesurfen">Kitesurfen</Link>
            </li>
            <li className="text-2xl py-2">
              <Link to="/kurse/sup">Stand Up Paddling (SUP)</Link>
            </li>
            <li className="text-2xl py-2">
              <Link to="/kurse/privatunterricht">Privatunterricht</Link>
            </li>
          </ul>
        </nav>
        <button
          className="absolute top-5 right-5 hover:cursor-pointer"
          onClick={() => {
            handleNavToggle();
            setSubNavOpen(false);
          }}
          tabIndex={0}
        >
          <Clear />
        </button>
      </div>
    </header>
  );
};

export default Header;
