import React from "react";

const Burger = () => {
  return (
    <svg
      width="36"
      height="28"
      viewBox="0 0 36 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="9.22308"
        x2="35"
        y2="9.22308"
        stroke="#102337"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="9"
        y1="17.2231"
        x2="35"
        y2="17.2231"
        stroke="#102337"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Burger;
