import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Logo = () => {
  return (
    <StaticImage
      src="../images/TS-Logo-Blue-1000px.png"
      alt="Tidens Surfhuus Logo"
      placeholder="blurred"
      objectFit="contain"
      width={130}
      height={62}
      quality={100}
      className="h-[44px] lg:h-[62px] lg:w-[130px]"
    />
  );
};

export default Logo;
