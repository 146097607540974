import React from "react";

const InstagramIcon = ({color}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 2H7.5C4.73858 2 2.5 4.23858 2.5 7V17C2.5 19.7614 4.73858 22 7.5 22H17.5C20.2614 22 22.5 19.7614 22.5 17V7C22.5 4.23858 20.2614 2 17.5 2Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 11.3701C16.6234 12.2023 16.4812 13.0523 16.0937 13.7991C15.7062 14.5459 15.0931 15.1515 14.3416 15.5297C13.5901 15.908 12.7384 16.0397 11.9077 15.906C11.0771 15.7723 10.3097 15.3801 9.71479 14.7852C9.11987 14.1903 8.72768 13.4229 8.59402 12.5923C8.46035 11.7616 8.59202 10.91 8.97028 10.1584C9.34854 9.40691 9.95414 8.7938 10.7009 8.4063C11.4477 8.0188 12.2977 7.87665 13.13 8.00006C13.9789 8.12594 14.7648 8.52152 15.3716 9.12836C15.9785 9.73521 16.3741 10.5211 16.5 11.3701Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6.5H18.01"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InstagramIcon;

InstagramIcon.defaultProps = {
  color: "white",
};
