import React from "react";

const Clear = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6667 10.6833L29.3167 8.33331L20 17.65L10.6833 8.33331L8.33333 10.6833L17.65 20L8.33333 29.3166L10.6833 31.6666L20 22.35L29.3167 31.6666L31.6667 29.3166L22.35 20L31.6667 10.6833Z"
        fill="#EAE0C9"
      />
    </svg>
  );
};

export default Clear;
