import React from "react";
import Header from "../header";
import Footer from "../footer";
import Seo from "../seo";

// TODO: Site meta
const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Seo
        title="Die Surfschule auf Langeoog"
        description="Tidens Surfhuus am Weststrand von Langeoog. Wir bieten Kitesurfkurse, Windsurfkurse, Wingsurfkurse, Wellenreitkurse und SUP Kurse an."
      />
      <Header />
      <div className="grow bg-secondary text-primary">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
