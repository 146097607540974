import React from "react";

const Arrow = ({color, className}) => {
  let arrowColor = 'white';
  if(color === 'secondary') {arrowColor = '#102337'};
  if(color === 'menu') arrowColor = '#EAE0C9';

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 5.39282L13.59 6.80282L18.17 11.3928H2V13.3928H18.17L13.58 17.9828L15 19.3928L22 12.3928L15 5.39282Z"
        fill={arrowColor}
      />
    </svg>
  );
};

export default Arrow;